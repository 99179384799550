export const useBreakpoint = () => {
  const widowWidth = ref(window?.innerWidth);
  // observe the window width and return the breakpoint
  const onResize = () => {
    widowWidth.value = window.innerWidth;
  };
  window?.addEventListener('resize', onResize);

  return computed(() => ({
    isMobile: widowWidth.value < 768,
    isTablet: widowWidth.value >= 768 && widowWidth.value < 1024,
    isDesktop: widowWidth.value >= 1024,
  })).value;
};
