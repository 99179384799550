import { BottomSheetType, BottomSheetTransitions } from '@robusta/headless-commerce-elements/common';

export const useToggleCommercialModal = () => {
  const { toggleDefinedAlias } = useBottomSheet<{ sku: string }>();
  function showCommercialModal(props?: { sku: string }) {
    if (!props?.sku) {
      // throw new Error('SKU is required');
      // TODO - handle error
    }

    const { isMobile } = useBreakpoint();
    toggleDefinedAlias(
      'commercialModal',
      BottomSheetType.DEFAULT,
      isMobile ? BottomSheetTransitions.BOTTOM_UP : BottomSheetTransitions.SLIDE,
      props,
    );
  }

  function close() {
    toggleDefinedAlias('commercialModal');
  }

  return {
    showCommercialModal,
    close,
  };
};
